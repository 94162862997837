<template>
  <HeaderLayout
    :navigation="navigation"
    :navigationMB="navigationMB"
    :showRightNavigation="showRightNavigation"
    :show-top-bar="showTopBar"
    :showPhoneMB="showPhoneMB"
    new-header
    v-if="hideHeader"
  >
    <template #right-end>
      <!--      <router-link target='_blank' class='ml-30' :to="{ name: 'signup-agent' }"><span class='gray-dark'>Travel Agent Login</span></router-link>-->
      <a class="ml-30 mb-ml-20" target="_blank" :href="goToAgentLogin"><span class="gray-dark">Travel Agent Login</span></a>
    </template>
  </HeaderLayout>
</template>

<script>
import HeaderLayout from './HeaderLayout'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { PHONE } from '~/constant/common'

export default {
  name: 'HeaderTraveller',
  components: { HeaderLayout },
  setup() {
    const route = useRoute()
    const {isDesktopOrTablet} = useDevice()

    const navigation = [
      // {
      //   title: 'Blog',
      //   url: `${process.env.VUE_APP_PUBLIC_DOMAIN_PUBLIC}/blog/`,
      // },
      {
        title: 'How It Works',
        url: '/how-it-works',
      },
    ]

    const navigationMB = [
      // {
      //   title: 'Free Cancellation Trips',
      //   name: 'free-cancelation',
      //   url: '/free-cancellation-trips',
      //   externalURL: '',
      // },
      {
        title: 'About Us',
        name: 'about',
        url: '/about-us',
        externalURL: '',
      },
      {
        title: 'Contact Us',
        name: 'contact',
        url: '/contact-us',
        externalURL: '',
      },
      {
        title: 'Blog',
        name: 'blog',
        url: '',
        externalURL: '/blog/',
      },
      {
        title: 'Careers',
        name: 'careers',
        url: '',
        externalURL: 'https://www.linkedin.com/jobs/search/?f_C=18090474&geoId=92000000&position=1&pageNum=0',
      },
    ]

    const goToAgentLogin = computed(() => {
      return useAppConfig().publicDomainAgent
    })
    const showTopBar = computed(() => {
      return !(
        route.name === 'enquiry-submit-global' ||
        route.name === 'enquiry-submit-destination' ||
        route.name === 'enquiry-submit-ld' ||
        route.name === 'enquiry-submit-trip'
      )
    })
    const showPhoneMB = computed(() => {
      return (
        route.name === 'enquiry-submit-global' ||
        route.name === 'enquiry-submit-destination' ||
        route.name === 'enquiry-submit-ld' ||
        route.name === 'enquiry-submit-trip'
      )
    })
    const showRightNavigation = computed(() => {
      return !(
        route.name === 'gallery-enquiry-steps' ||
        route.name === 'trip-gallery-enquiry-steps' ||
        route.name === 'enquiry-steps' ||
        route.name === 'enquiry-local-designer' ||
        route.name === 'enquiry-submit-global' ||
        route.name === 'enquiry-submit-destination' ||
        route.name === 'enquiry-submit-trip' ||
        route.name === 'enquiry-submit-ld' ||
        route.name === 'feedback' ||
        route.name === 'custom-group-tours'
      )
    })

    const hideHeader = computed(() => {
      const pages = ['enquiry-submit-global', 'enquiry-submit-destination', 'enquiry-submit-ld', 'enquiry-submit-trip', 'withintrepid']
      if (pages.indexOf(route.name) !== -1 && isDesktopOrTablet) return false
      return true
    })

    return { navigation, navigationMB, goToAgentLogin, showRightNavigation, showTopBar, showPhoneMB, PHONE, hideHeader }
  },
}
</script>

<style scoped></style>
